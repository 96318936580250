import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import profileIcon from "../../images/profileicon.png";
import logoutIcon from "../../images/logout.png";

import logout from "../../images/logout.png";
import UserHeader from "../components/user-header";
import { logOut } from "../../api/auth";
import { toast } from "react-toastify";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
  const { i18n } = useTranslation("en");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const logout = async () => {
    try {
      setLoading(true);
      const response = await logOut();
      if (response.remote === "success") {
        navigate("/");
      }
    } catch (error) {
      console.log("error",error)
      toast.error(`Error in logging off:${error}`);
     
    } finally {
      setLoading(false);
       localStorage.clear();
       sessionStorage.clear();
       localStorage.setItem("selectedLanguage", "en-US");
       i18n.changeLanguage("en-US");
       navigate("/");
    }
  };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{ p: 3, mb: 10 }}
          dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        >
          <Typography
            variant="h4"
            sx={{ color: "#fff", fontSize: "20px", fontWeight: 600, mb: 3 }}
          >
            {t("my_profile")}
          </Typography>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={1}
              component={Link}
              to="/user/manage-profile"
              sx={{
                color: "#FBC515",
                textDecoration: "none",
                border: "1px solid #4F4F4F",
                background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
                borderRadius: "8px",
                p: 2,
              }}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <img src={profileIcon} alt="" />
                <Box component={"span"} className="backArrow">
                  {t("profile")}
                </Box>
              </Stack>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.9668 13.28L10.3135 8.9333C10.8268 8.41997 10.8268 7.57997 10.3135 7.06664L5.9668 2.71997"
                    stroke="white"
                    stroke-width="0.8125"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={1}
              component={Link}
              // to="/user/dashboard"
              sx={{
                color: "#FBC515",
                textDecoration: "none",
                border: "1px solid #4F4F4F",
                background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
                borderRadius: "8px",
                p: 2,
              }}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <img src={logoutIcon} alt="" />
                <Box component={"span"} onClick={logout} className="backArrow">
                  {t("logout")}
                </Box>
              </Stack>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.9668 13.28L10.3135 8.9333C10.8268 8.41997 10.8268 7.57997 10.3135 7.06664L5.9668 2.71997"
                    stroke="white"
                    stroke-width="0.8125"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
      <UserHeader />
    </>
  );
}
